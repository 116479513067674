.palette {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: middle;
  margin-top: 3px;
  margin-bottom: 3px;
}

.c1, .c2, .c3, .c4 {
  width: 16px;
  height: 16px;
  margin-right: 1px;
}

.gray0 > .c1 { background: #ffffff}
.gray0 > .c2 { background: #acacac}
.gray0 > .c3 { background: #626262}
.gray0 > .c4 { background: #000000}

.green0 > .c1 { background: #31E673 }
.green0 > .c2 { background: #00B400 }
.green0 > .c3 { background: #186A00 }
.green0 > .c4 { background: #205200 }

.green1 > .c1 { background: #EFFFE0 }
.green1 > .c2 { background: #AED798 }
.green1 > .c3 { background: #539274 }
.green1 > .c4 { background: #193441 }

.green2 > .c1 { background: #e0f8a0 }
.green2 > .c2 { background: #78c838 }
.green2 > .c3 { background: #488818 }
.green2 > .c4 { background: #081800 }

.green3 > .c1 { background: #9bbc0f }
.green3 > .c2 { background: #8bac0f }
.green3 > .c3 { background: #306230 }
.green3 > .c4 { background: #0f380f }

.green4 > .c1 { background: #00b581 }
.green4 > .c2 { background: #009a71 }
.green4 > .c3 { background: #00694a }
.green4 > .c4 { background: #004f3b }

.green5 > .c1 { background: #F8F8C8 }
.green5 > .c2 { background: #B8C058 }
.green5 > .c3 { background: #808840 }
.green5 > .c4 { background: #405028 }

.sgb1a > .c1 { background: #f8e8c8 }
.sgb1a > .c2 { background: #d89048 }
.sgb1a > .c3 { background: #a82820 }
.sgb1a > .c4 { background: #301850 }

.sgb1b > .c1 { background: #d8d8c0 }
.sgb1b > .c2 { background: #c8b070 }
.sgb1b > .c3 { background: #b05010 }
.sgb1b > .c4 { background: #000000 }

.sgb1c > .c1 { background: #f8c0f8 }
.sgb1c > .c2 { background: #e89850 }
.sgb1c > .c3 { background: #983860 }
.sgb1c > .c4 { background: #383898 }

.sgb1d > .c1 { background: #f8f8a8 }
.sgb1d > .c2 { background: #c08048 }
.sgb1d > .c3 { background: #f80000 }
.sgb1d > .c4 { background: #501800 }

.sgb1e > .c1 { background: #f8d8b0 }
.sgb1e > .c2 { background: #78c078 }
.sgb1e > .c3 { background: #688840 }
.sgb1e > .c4 { background: #583820 }

.sgb1f > .c1 { background: #d8e8f8 }
.sgb1f > .c2 { background: #e08850 }
.sgb1f > .c3 { background: #a80000 }
.sgb1f > .c4 { background: #004010 }

.sgb1g > .c1 { background: #000050 }
.sgb1g > .c2 { background: #00a0e8 }
.sgb1g > .c3 { background: #787800 }
.sgb1g > .c4 { background: #f8f858 }

.sgb1h > .c1 { background: #f8e8e0 }
.sgb1h > .c2 { background: #f8b888 }
.sgb1h > .c3 { background: #804000 }
.sgb1h > .c4 { background: #301800 }

.sgb2a > .c1 { background: #f0c8a0 }
.sgb2a > .c2 { background: #c08848 }
.sgb2a > .c3 { background: #287800 }
.sgb2a > .c4 { background: #000000 }

.sgb2b > .c1 { background: #f8f8f8 }
.sgb2b > .c2 { background: #f8e850 }
.sgb2b > .c3 { background: #f83000 }
.sgb2b > .c4 { background: #500058 }

.sgb2c > .c1 { background: #f8c0f8 }
.sgb2c > .c2 { background: #e88888 }
.sgb2c > .c3 { background: #7830e8 }
.sgb2c > .c4 { background: #282898 }

.sgb2d > .c1 { background: #f8f8a0 }
.sgb2d > .c2 { background: #00f800 }
.sgb2d > .c3 { background: #f83000 }
.sgb2d > .c4 { background: #000050 }

.sgb2e > .c1 { background: #f8c880 }
.sgb2e > .c2 { background: #90b0e0 }
.sgb2e > .c3 { background: #281060 }
.sgb2e > .c4 { background: #100810 }

.sgb2f > .c1 { background: #d0f8f8 }
.sgb2f > .c2 { background: #f89050 }
.sgb2f > .c3 { background: #a00000 }
.sgb2f > .c4 { background: #180000 }

.sgb2g > .c1 { background: #68b838 }
.sgb2g > .c2 { background: #e05040 }
.sgb2g > .c3 { background: #e0b880 }
.sgb2g > .c4 { background: #001800 }

.sgb2h > .c1 { background: #f8f8f8 }
.sgb2h > .c2 { background: #b8b8b8 }
.sgb2h > .c3 { background: #707070 }
.sgb2h > .c4 { background: #000000 }

.sgb3a > .c1 { background: #f8d098 }
.sgb3a > .c2 { background: #70c0c0 }
.sgb3a > .c3 { background: #f86028 }
.sgb3a > .c4 { background: #304860 }

.sgb3b > .c1 { background: #d8d8c0 }
.sgb3b > .c2 { background: #e08020 }
.sgb3b > .c3 { background: #005000 }
.sgb3b > .c4 { background: #001010 }

.sgb3c > .c1 { background: #e0a0c8 }
.sgb3c > .c2 { background: #f8f878 }
.sgb3c > .c3 { background: #00b8f8 }
.sgb3c > .c4 { background: #202058 }

.sgb3d > .c1 { background: #f0f8b8 }
.sgb3d > .c2 { background: #e0a878 }
.sgb3d > .c3 { background: #08c800 }
.sgb3d > .c4 { background: #000000 }

.sgb3e > .c1 { background: #f8f8c0 }
.sgb3e > .c2 { background: #e0b068 }
.sgb3e > .c3 { background: #b07820 }
.sgb3e > .c4 { background: #504870 }

.sgb3f > .c1 { background: #7878c8 }
.sgb3f > .c2 { background: #f868f8 }
.sgb3f > .c3 { background: #f8d000 }
.sgb3f > .c4 { background: #404040 }

.sgb3g > .c1 { background: #60d850 }
.sgb3g > .c2 { background: #f8f8f8 }
.sgb3g > .c3 { background: #c83038 }
.sgb3g > .c4 { background: #380000 }

.sgb3h > .c1 { background: #e0f8a0 }
.sgb3h > .c2 { background: #78c838 }
.sgb3h > .c3 { background: #488818 }
.sgb3h > .c4 { background: #081800 }

.sgb4a > .c1 { background: #f0a868 }
.sgb4a > .c2 { background: #78a8f8 }
.sgb4a > .c3 { background: #d000d0 }
.sgb4a > .c4 { background: #000078 }

.sgb4b > .c1 { background: #f0e8f0 }
.sgb4b > .c2 { background: #e8a060 }
.sgb4b > .c3 { background: #407838 }
.sgb4b > .c4 { background: #180808 }

.sgb4c > .c1 { background: #f8e0e0 }
.sgb4c > .c2 { background: #d8a0d0 }
.sgb4c > .c3 { background: #98a0e0 }
.sgb4c > .c4 { background: #080000 }

.sgb4d > .c1 { background: #f8f8b8 }
.sgb4d > .c2 { background: #90c8c8 }
.sgb4d > .c3 { background: #486878 }
.sgb4d > .c4 { background: #082048 }

.sgb4e > .c1 { background: #f8d8a8 }
.sgb4e > .c2 { background: #e0a878 }
.sgb4e > .c3 { background: #785888 }
.sgb4e > .c4 { background: #002030 }

.sgb4f > .c1 { background: #b8d0d0 }
.sgb4f > .c2 { background: #d880d8 }
.sgb4f > .c3 { background: #8000a0 }
.sgb4f > .c4 { background: #380000 }

.sgb4g > .c1 { background: #b0e018 }
.sgb4g > .c2 { background: #b82058 }
.sgb4g > .c3 { background: #281000 }
.sgb4g > .c4 { background: #008060 }

.sgb4h > .c1 { background: #F8F8C8 }
.sgb4h > .c2 { background: #B8C058 }
.sgb4h > .c3 { background: #808840 }
.sgb4h > .c4 { background: #405028 }
